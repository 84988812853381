const state = {
  bracket: []
}

const mutations = {
  SET_BRACKET (state, bracket) {
    state.bracket = bracket || []
  },
  UPDATE_MATCHES (state, matches) {
    state.matches = matches
  }
}

const actions = {
  SOCKET_UPDATE_MATCH ({ state, commit }, match) {
    commit('UPDATE_MATCHES', {
      ...state.matches,
      [match.id]: {
        ...state.matches[match.id],
        ...match
      }
    })
  }
}

const getters = {
  fetchMatches: state => state.matches
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
