const state = {
  _loading: false,
  _step: null,
  _validPhoneNumber: false,
  _validPaymentMethod: false,
  _savePaymentMethod: false,
  _paymentMethodID: null,
  _paymentAddressCountry: null,
  _totalAmount: null,
  _methodType: 'credit_card',
  _confirmManualPayment: false,
  _manualPaymentObj: {},
  _terminalPaymentObj: {},
  _eventIdsList: [],
  _orgCustomerID: null,
  _shippingType: null,
  _showInfoOrgMessage: false
}

const getters = {
  loading: (state) => {
    return state._loading
  },
  showInfoOrgMessage: (state) => {
    return state._showInfoOrgMessage
  },
  step: (state) => {
    return state._step
  },
  validPhoneNumber: (state) => {
    return state._validPhoneNumber
  },
  validPaymentMethod: (state) => {
    return state._validPaymentMethod
  },
  savePaymentMethod: (state) => {
    return state._savePaymentMethod
  },
  paymentAddressCountry: (state) => {
    return state._paymentAddressCountry
  },
  paymentMethodID: (state) => {
    return state._paymentMethodID
  },
  totalAmount: (state) => {
    return state._totalAmount
  },
  methodType: (state) => {
    return state._methodType
  },
  confirmManualPayment: (state) => {
    return state._confirmManualPayment
  },
  manualPaymentObj: (state) => {
    return state._manualPaymentObj
  },
  terminalPaymentObj: (state) => {
    return state._terminalPaymentObj
  },
  eventIdsList: (state) => {
    return state._eventIdsList
  },
  orgCustomerID: (state) => {
    return state._orgCustomerID
  },
  shippingType: (state) => {
    return state._shippingType
  }
}

const actions = {

}

const mutations = {
  RESET (state, value) {
    state._loading = false
    state._step = null
    state._validPhoneNumber = false
    state._validPaymentMethod = false
    state._savePaymentMethod = false
    state._paymentMethodID = null
    state._paymentAddressCountry = null
  },
  SET_LOADING (state, value) {
    state._loading = value
  },
  SET_INFO_ORG_MESSAGE (state, value) {
    state._showInfoOrgMessage = value
  },
  SET_STEP (state, value) {
    state._step = value
  },
  SET_VALID_PHONE_NUMBER (state, value) {
    state._validPhoneNumber = value
  },
  SET_VALID_PAYMENT_METHOD (state, value) {
    state._validPaymentMethod = value
  },
  SET_SAVE_PAYMENT_METHOD (state, value) {
    state._savePaymentMethod = value
  },
  SET_PAYMENT_METHOD_ID (state, value) {
    state._paymentMethodID = value
  },
  SET_PAYMENT_ADDRESS_COUNTRY (state, value) {
    state._paymentAddressCountry = value
  },
  SET_TOTAL_AMOUNT (state, value) {
    state._totalAmount = value
  },
  SET_METHOD_TYPE (state, value) {
    state._methodType = value
  },
  SET_CONFIRM_MANUAL_PAYMENT (state, value) {
    state._confirmManualPayment = value
  },
  SET_MANUAL_PAYMENT_OBJ (state, value) {
    state._manualPaymentObj = value
  },
  SET_TERMINAL_PAYMENT_OBJ (state, value) {
    state._terminalPaymentObj = value
  },
  SET_EVENT_IDS_LIST (state, value) {
    state._eventIdsList = value
  },
  SET_ORG_CUSTOMER_ID (state, value) {
    state._orgCustomerID = value
  },
  SET_SHIPPING_TYPE (state, value) {
    state._shippingType = value
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
