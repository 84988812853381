// Takes an error object and parses a proper error message
import store from '@/store/'

export default (err, storeContext) => {
  let message = null

  if (err.response &&
      err.response.data &&
      err.response.data.message) {
    message = err.response.data.message
  } else {
    message = err.message
  }

  if (storeContext) {
    // If a vuex store context is passed show the snackbar
    store.commit('snackbar/SHOW_SNACKBAR', {
      message,
      color: 'error'
    }, { root: true })
  } else {
    // Otherwise return the formatted message
    return message
  }
}
