import Vue from 'vue'

Vue.mixin({
  methods: {
    importLogo (type, sport) {
      const sports = [
        'baseball',
        'basketball',
        'beach_soccer',
        'football',
        'hockey',
        'sports',
        'lacrosse',
        'ringette',
        'soccer',
        'softball',
        'volleyball'
      ]
      if (sports.find(e => e === sport)) {
        return require(`@/assets/defaults/${type}/${sport}.png`)
      } else {
        return require('@/assets/defaults/tournament/DefaultLogo.png')
      }
    }
  }
})
