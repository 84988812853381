const mutations = {
  clear (state) {
    state.data = []
  },
  setLoading (state, val) {
    state.loading = val
  },
  set (state, { data }) {
    if (!Array.isArray(data)) {
      console.warn('Could not set fetched data: payload is not an array')
      return
    }
    if (data.length === 0) {
      state.data = []
      return
    }
    const existingIDs = state.data.map((item) => item.id)
    const newData = [...state.data]
    data.forEach((item) => {
      const idx = existingIDs.indexOf(item.id)
      if (idx > -1) {
        newData[idx] = item
      } else {
        newData.push(item)
      }
    })
    state.data = newData
  },
  setActive (state, data) {
    if (typeof data !== 'object' || Array.isArray(data)) {
      console.warn('Could not set fetched data: payload is not an object')
      return
    }
    state.active = data
  }
}

export default mutations
