<template>
  <v-app v-if="loading">
    <v-main>
      <v-container>
        <v-row>
          <v-col
            class="text-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <AppBar v-if="showAppBar" />
    <ShoppingCart v-if="showMyComponent" />
    <v-main>
      <v-container
        fluid
        class="pa-0"
        style="height: 100%"
      >
        <router-view />
        <snackbar />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Snackbar from '@/components/Snackbar'
import ShoppingCart from '@/views/ShoppingCart/ShoppingCart'

export default {
  name: 'App',
  components: {
    AppBar,
    Snackbar,
    ShoppingCart
  },
  data () {
    return {
      publicOrganizations: null
    }
  },
  head: {
    meta: [
      { name: 'Tournkey', content: 'Tournkey' },
      {
        name: 'description',
        content:
          'Tournkey Tournament Software is an intuitive system designed to mitigate the difficulties of managing youth and amateur sporting events.',
        id: 'desc'
      }
    ]
  },
  computed: {
    loading () {
      return this.$store.state.auth.loading
    },
    showMyComponent () {
      return (
        this.$route.name !== 'Login' &&
        this.$route.name !== 'Register'
      )
    },
    showAppBar () {
      return ![
        '/login',
        '/register',
        '/recover'
      ].includes(this.$route.path)
    }
  },
  async created () {
    await this.$store.dispatch('auth/checkExpAccessToken')
    await this.$store.dispatch('auth/getUserInformation')
  }
}
</script>
