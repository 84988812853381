
import { v1 as uuidv1 } from 'uuid'
import { EventBus } from '@/services/event-bus'

export default {
  methods: {
    async addMagentoToCard (product, showCart = true) {
      try {
        const accessToken = localStorage.getItem('accessToken')
        let uniqueId = localStorage.getItem('unique-id')
        if (!accessToken && !uniqueId) {
          const userId = uuidv1()
          uniqueId = userId
          localStorage.setItem('unique-id', userId)
        }

        const { sku } = product
        let currencyCart
        if (sku?.currency === 'cad') {
          currencyCart = 'ca'
        } else if (sku?.currency === 'usd') {
          currencyCart = 'us'
        }

        const requestData = {
          body: {
            shadowId: uniqueId,
            // templateTicketTypeID: product.id,
            type: product.type,
            quantity: sku.selectedQuantity,
            skuID: product.skuID,
            origin: currencyCart
          }
        }

        localStorage.setItem('currency-cart', currencyCart)
        await this.$store.dispatch('sessionItem/create', requestData)
        if (showCart) EventBus.$emit('show-cart', true)
        return true
      } catch (error) {
        const alertWarning = error?.response?.data?.data?.typeMessage || null
        const errorCode = error.response.status
        const errorMessage = error.response?.data?.message || error.message
        if (error.response && errorCode === 500 && errorMessage.includes('same organization') && alertWarning === 'warning') {
          this.$store.commit(
            'snackbar/SHOW_SNACKBAR',
            { message: errorMessage, color: alertWarning },
            { root: true }
          )
          this.$store.commit('checkoutProcess/SET_INFO_ORG_MESSAGE', true)
        } else {
          this.$store.commit(
            'snackbar/SHOW_SNACKBAR',
            { message: errorMessage, color: 'error' },
            { root: true }
          )
        }
        return false
      }
    },

    prepareSelectedAtrtibutes (swatchItems) {
      const selectedAttributes = {}
      swatchItems.forEach(item => {
        selectedAttributes[`attribute_code_${item.label.toLowerCase()}`] = parseInt(item.value.value_index)
      })

      return selectedAttributes
    },
    prepareCustomAttributes (configurables, swatchItems) {
      const customAttributes = []
      configurables.forEach(conf => {
        const attributes = {
          id: conf.id,
          status: conf.status,
          stock: conf.extension_attributes.stock_item.qty
        }
        conf.custom_attributes.forEach(attr => {
          swatchItems.forEach(item => {
            if (attr.attribute_code === item.label.toLowerCase()) {
              attributes[`attribute_code_${item.label.toLowerCase()}`] = parseInt(attr.value)
            }
          })
        })
        customAttributes.push(attributes)
      })

      return customAttributes
    },
    findMagentoProduct (selectedAttributes, customAttributes) {
      let attributes
      /* eslint no-unreachable-loop: ["error", { "ignore": ["ForInStatement", "ForOfStatement"] }] */
      for (const key in selectedAttributes) {
        attributes = customAttributes.filter(el => el[key] === selectedAttributes[key])
        delete selectedAttributes[key]
        break
      }

      if (attributes.length === 1) return attributes[0]
      else if (attributes.length > 1) {
        return this.findMagentoProduct(selectedAttributes, attributes)
      }

      return null
    },
    filterMagentoValues (swatchItem, nextItem, configurables) {
      const key = `attribute_code_${swatchItem.label.toLowerCase()}`
      nextItem.values = nextItem.values.map(el => {
        el.disabled = true
        return el
      })

      const customAtributes = this.prepareCustomAttributes(configurables, [swatchItem])
      const atributes = customAtributes.filter(el => el[key] === swatchItem.value.value_index)
      atributes.forEach(attr => {
        const item = configurables.find(x => x.id === attr.id)
        item.custom_attributes.forEach(custom => {
          if (custom.attribute_code === nextItem.label.toLowerCase()) {
            const index = nextItem.values.findIndex(i => parseInt(i.value_index) === parseInt(custom.value))
            if (index >= 0) {
              if (parseInt(item.status) === 0) nextItem.values[index].disabled = true
              else if (parseInt(item.extension_attributes.stock_item.qty) === 0) nextItem.values[index].disabled = true
              else nextItem.values[index].disabled = false
            }
          }
        })
      })

      return nextItem.values
    }
  }
}
