/* eslint-disable no-empty */
/* eslint-disable no-useless-catch */
import * as API from '@sport-travel/api'

const modules = API.default

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const actions = (resource) => ({
  async fetchById (context, { id, params, headers }) {
    try {
      const { data } = await modules[capitalizeFirstLetter(resource)].fetchById(id, params, headers)
      context.commit('setActive', data)
      return data
    } catch (err) {
      return err
    } finally {
    }
  },
  async fetchByIdNoError (context, { id, params, headers }) {
    try {
      const { data } = await modules[capitalizeFirstLetter(resource)].fetchById(id, params, headers)
      context.commit('setActive', data)
      return data
    } catch (err) {
      if (err) {
        return null
      }
    } finally {
    }
  },
  async fetch (context, { params, headers }) {
    try {
      const { data } = await modules[capitalizeFirstLetter(resource)].fetch(params, headers)
      context.commit('set', data)
      return data
    } catch (err) {
      throw err
    } finally {
    }
  },
  async create (context, { body, params, headers }) {
    try {
      const { data } = await modules[capitalizeFirstLetter(resource)].post(body, params, headers)
      return data
    } catch (err) {
      throw err
    } finally {
    }
  },
  async update (context, { id, body, params, headers }) {
    try {
      const response = await modules[capitalizeFirstLetter(resource)].patch(id, body, params, headers)
      context.commit('setActive', response.data)
      return response
    } catch (err) {
      throw err
    } finally {
    }
  },
  async put (context, { id, body, params, headers }) {
    try {
      const response = await modules[capitalizeFirstLetter(resource)].put(id, body, params)
      return response
    } catch (err) {
      throw err
    } finally {
    }
  },
  async delete (context, { id, body, params, headers }) {
    try {
      const response = await modules[capitalizeFirstLetter(resource)].delete(id, body, params, headers)
      return response
    } catch (err) {
      throw err
    }
  }
})

export default actions
