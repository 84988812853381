import { v1 as uuidv1 } from 'uuid'
import { EventBus } from '@/services/event-bus'
import store from '@/store/index'

const state = {
  items: JSON.parse(localStorage.getItem('cart_coach')) || {
    usd: [],
    cad: []
  }
}

const actions = {
  async ADD_ITEMS_TO_CART ({ dispatch, commit }, body) {
    if (!localStorage.getItem('accessToken')) {
      if (!localStorage.getItem('unique-id')) {
        localStorage.setItem('unique-id', uuidv1())
      }
    }
    const sku = body.registration?.division?.product?.skus.sort((a, b) => a.created - b.created).reverse().find((t) => t.status === 'Active')
    try {
      await store.dispatch('sessionItem/create', {
        body: {
          shadowId: localStorage.getItem('unique-id'),
          type: 'registrations',
          quantity: 1,
          skuID: sku.id,
          registration: body.registration.id,
          paymentOption: body.paymentOption
        },
        headers: {
          role: 'coach'
        }
      })
      const currentQty = parseInt(localStorage.getItem('total-quantity')) + 1
      localStorage.setItem('total-quantity', currentQty)
      EventBus.$emit('total-quantity', currentQty)
    } catch (err) {
      commit('snackbar/SHOW_SNACKBAR', {
        message: err.response?.data?.message || err.message,
        color: 'error'
      }, { root: true })
    } finally {
      this.loading = false
    }
  },
  async SET_DIVISION_ON_REGISTRATION ({ state, dispatch, commit }, registration) {
    // Check if item is already in cart
    const allItems = state.items.usd.concat(state.items.cad).map(x => x.id)

    if (allItems.includes(registration.id)) {
      return { error: true }
    }
    try {
      // Add division info to registration
      registration.division = await dispatch('division/fetchById', {
        id: registration.divisionID,
        headers: {
          role: 'coach'
        }
      }, { root: true })
      return { success: true }
    } catch (err) {
      commit('app/SET_LOADING', false, { root: true })
      return { error: true }
    }
  }
}

export default {
  state,
  actions,
  namespaced: true
}
