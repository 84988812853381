<template>
  <TK-MultiCart
    v-if="showMyComponent"
    :action="showCart"
    @itemsToShow="(value) => setItems(value)"
  />
</template>
<script>

import { EventBus } from '@/services/event-bus'
export default {
  name: 'ShoppingCart',
  data () {
    return {
      showCart: false
    }
  },
  computed: {
    showMyComponent () {
      if (this.$route.name === null) return false
      return (
        this.$route.name !== 'CheckoutCart' &&
        this.$route.name !== 'ValidatePayment'
      )
    }
  },
  created () {
    EventBus.$on('show-cart', this.handleGlobalCart)
  },
  methods: {
    setItems (value) {
      this.emitGlobalEvent(value)
      localStorage.setItem('total-quantity', value)
    },
    emitGlobalEvent (quantity) {
      EventBus.$emit('total-quantity', quantity)
    },
    handleGlobalCart (value) {
      this.showCart = value
    }
  }
}
</script>

<style>

</style>
