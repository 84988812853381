<template>
  <v-snackbar
    v-model="snackbar.visible"
    :color="snackbar.color"
    bottom
    :timeout="timeout"
  >
    {{ snackbar.message }}
    <v-progress-linear
      absolute
      bottom
      color="white"
      :value="progressValue"
    />
    <template #action="{ attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        @click="snackbar.visible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SnackBar',
  data: () => ({
    timeout: 5000,
    currentTime: 0,
    progressValue: 0
  }),
  computed: {
    ...mapState({
      snackbar: state => state.snackbar
    })
  },
  watch: {
    'snackbar.visible' (value) {
      if (value) {
        this.currentTime = 0
        this.syncProgress()
      }
    }
  },
  methods: {
    syncProgress () {
      if (!this.snackbar.visible) return
      this.progressValue = Math.floor(100 * (this.currentTime / this.timeout))

      setTimeout(() => {
        this.currentTime += 100
        if (this.timeout <= this.currentTime) {
          setTimeout(() => {
            this.currentTime = 0
            this.snackbar.visible = false
          }, 500)
        } else {
          this.syncProgress()
        }
      }, 100)
    }
  }
}
</script>
