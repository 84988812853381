import _isEmpty from 'lodash/isEmpty'

const getters = {
  getData (state) {
    return state.data
  },
  hasData (state) {
    return state.data && state.data.length > 0
  },
  getDataById: (state) => (id) => {
    return state.data.find(x => x.id === id)
  },
  getActive (state) {
    return state.active
  },
  hasActive (state) {
    return state.active && !_isEmpty(state.active)
  }
}

export default getters
