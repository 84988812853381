const canadianProvinces = require('./provinces/CA.json')
const americanStates = require('./provinces/US.json')

module.exports = [{
  title: 'Canada',
  value: 'CA',
  provinces: canadianProvinces
}, {
  title: 'United States',
  value: 'US',
  provinces: americanStates
}]
