const state = {
  filter: localStorage.getItem('livepageFilter')
}

const getters = {
  filter: (state) => {
    return state.filter
      ? JSON.parse(state.filter)
      : {
          tournamentID: null,
          divisionID: null,
          tabID: null
        }
  }
}

const actions = {
  setFilter (context, filter) {
    context.commit('SET_FILTER', filter)
  },
  resetFilter (context) {
    context.commit('SET_FILTER', {})
  }
}

const mutations = {
  SET_FILTER (state, filter) {
    const json = {
      tournamentID: filter.tournamentID || null,
      divisionID: filter.divisionID || null,
      tabID: filter.tabID !== null ? filter.tabID : null
    }

    localStorage.setItem('livepageFilter', JSON.stringify(json))
    localStorage.setItem('TournamentID', filter.tournamentID)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
