<template>
  <v-app-bar
    width="100vw"
    app
    class="elevation-1 black principal-nav-bar"
    clipped-left
    tile
    dark
    style="z-index: 99"
  >
    <v-app-bar-nav-icon
      v-if="showNavIcon"
      @click.stop="showDrawer"
    />
    <v-toolbar-title>
      <router-link to="/">
        <v-img
          :class="$vuetify.breakpoint.mdAndDown ? 'ml-0' : 'ml-4'"
          :width="$vuetify.breakpoint.mdAndDown ? '145px' : '180px'"
          :src="require('@/assets/logo/logo_horizontal_green_white.png')"
        />
      </router-link>
    </v-toolbar-title>
    <v-toolbar-items>
      <v-btn
        v-if="showMyComponent"
        class="transparent text-none"
        stacked
        @click.stop="drawer = !drawer"
      >
        <v-badge
          :content="totalQuantity || '0'"
          color="primary"
        >
          <v-icon color="primary">
            icon-emptyCart
          </v-icon>
        </v-badge>
      </v-btn>
      <TK-App-Bar-User-Menu v-if="showUserMenu" />
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { EventBus } from '@/services/event-bus'
import { mapGetters } from 'vuex'
export default {
  name: 'Appbar',
  data () {
    return {
      drawer: false,
      totalQuantity: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    }),
    showNavIcon () {
      return this.$store.state.app.showNavIcon
    },
    showMyComponent () {
      if (this.$route.name === null) return false
      return (
        this.$route.name !== 'Login' &&
        this.$route.name !== 'Register' &&
        this.$route.name !== 'CheckoutCart' &&
        this.$route.name !== 'ValidatePayment'
      )
    },
    showUserMenu () {
      return !!this.user.id
    }
  },
  watch: {
    drawer () {
      EventBus.$emit('show-cart', this.drawer)
    }
  },
  created () {
    this.totalQuantity = localStorage.getItem('total-quantity')
    EventBus.$on('total-quantity', this.handleEventGlobal)
    EventBus.$on('show-cart', this.handleGlobalCart)
  },
  methods: {
    handleEventGlobal (value) {
      this.totalQuantity = value
    },
    handleGlobalCart (value) {
      this.drawer = value
    },
    showDrawer () {
      return this.$store.commit('app/SET_SHOW_DRAWER')
    }
  }
}
</script>

<style>
.principal-nav-bar .v-toolbar__content {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}
</style>
