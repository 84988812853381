const state = {
  visible: false,
  message: null,
  color: null
}

const mutations = {
  SHOW_SNACKBAR (state, { message, color }) {
    state.visible = false
    state.message = message || null
    state.color = color || 'black'
    state.visible = true
    state.timeout = 20000
  }
}

export default {
  state,
  mutations,
  namespaced: true
}
